<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="wrap">
      <div class="h1">
        2024年度河北省医学影像质控中心工作会议<br />
        成功举办
      </div>
      <section>
        <div class="div_p">
          为进一步推动全省各级医疗机构神经影像学质控工作的纵深开展，聚焦国内外神经-精神影像研究的最新动态，5月25日医科大学第一医院、首都医科大学宣武医院河北医院承办的河北省医学影像质控中心2024年工作会议、“太行之影”—河北省第二届医学影像质控高峰论坛在我院精致报告厅隆重召开。河北省神经科学学会神经放射学分会第二届第二次学术会议、河北省预防医学会放射学专委会第二届第四次会议、河北省健康学会医学影像管理分会第一届第七次会议同期召开。
        </div>
        <div>
          <img src="@/assets/HY20240623/1.png" alt="">
        </div>
        <div class="div_p">
          河北医科大学第一医院党委书记赵增仁教授出席会议并致辞。他表示，近年来医院秉持精致医院建设理念，在国家区域医疗中心建设项目的加持下，医院发展与学科建设均取得了丰硕成果，尤其是精神—神经疾病的融合发展已处于全国领先水平。本次会议聚焦神经影像学质控工作，打造高水平学习平台，将进一步助力河北省神经影像质控工作的快速发展。
        </div>
        <div>
          <img src="@/assets/HY20240623/2.png" alt="">
        </div>
        <div class="div_p">
          国家级放射影像专业质控中心主任金征宇教授线上视频致辞。他对河北省医学影像质控工作取得的成效给予了充分肯定，对河北省的影像质控工作也给予了更高的期望，同时也对下一步工作提出了详细的意见和建议。
        </div>
        <div>
          <img src="@/assets/HY20240623/3.png" alt="">
        </div>
        <div class="div_p">
          首都医科大学宣武医院副书记兼副院长卢洁教授在致辞中表示，规范化的医学影像质控工作，可以为患者提供更优质的服务，也将助力医院的高质量发展。同时她明确了医学影像质控工作的重要性与必要性，同时也提出了影像互认工作中面临的困难与挑战。
        </div>
        <div>
          <img src="@/assets/HY20240623/4.png" alt="">
        </div>
        <div class="div_p">
          河北省医学影像质控中心主任刘怀军教授对国家影像质控中心的肯定以及各地市级影像质控中心的支持表示感谢，未来河北省医学影像质控中心将继续细化、落实好影像质控的各项工作，为京津冀医学影像互认筑牢基础。
        </div>
        <div>
          <img src="@/assets/HY20240623/5.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20240623/6.png" alt="">
        </div>
        <div class="div_p">
          河北省医学会放射学分会主任委员时高峰教授及河北省医师协会放射医师分会主任委员耿左军教授出席分别致辞，他们对近年来河北医大一院放射与核医学科在影像质控方面取得成绩表示了肯定，也对今后的放射工作提出了新的期望和具体要求。
        </div>
        <div>
          <img src="@/assets/HY20240623/7.png" alt="">
        </div>
        <div class="div_p">
          河北医科大学第一医院放射与核医学科主任王勇教授任大会执行主席并主持会议。
        </div>
        <div class="div_p">
          接下来的学术会议环节，多名省内外知名神经放射学专家进行了精彩纷呈的学术讲座。
        </div>
        <div>
          <img src="@/assets/HY20240623/8.png" alt="">
        </div>
        <div class="div_p">
          卢洁教授针对“多模态影像助力缺血性脑卒中临床诊疗”进行了精彩报告，她介绍了宣武医院急性卒中绿色通道建设的经验分享，对比分析了各种影像技术及人工智能对急、慢性脑卒中的诊断价值，并对多学科协作中影像科面临的挑战提出了新的思考与见解。
        </div>
        <div>
          <img src="@/assets/HY20240623/9.png" alt="">
        </div>
        <div class="div_p">
          孙昊教授对放射影像质控指标的内涵及建立流程进行了深入解析，他向大家分析质控指标的重要性和制定过程的严谨性，介绍我国放射影像质控中心的工作进展，特别指出了危急值的标准制定的重要性，呼吁各级医院重视并积极参与质控指标的改进。
        </div>
        <div>
          <img src="@/assets/HY20240623/10.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20240623/11.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20240623/12.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20240623/13.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20240623/14.png" alt="">
        </div>
        <div class="div_p">
          河北医科大学第二医院刘怀军教授、河北医科大学第一医院任庆云教授及刘斋教授、山东大学齐鲁医院孟祥水教授、北京天坛医院刘亚欧教授就神经影像学方面的最新研究学术成果及诊疗经验进行精彩的学术报告。
        </div>
        <div>
          <img src="@/assets/HY20240623/15.png" alt="">
        </div>
        <div class="div_p">
          最后，王勇教授详细解读2024年度省医学影像质控中心重点工作安排，并通告了第七批京津冀地区医学影像检查结果互认医疗机构遴选的时间表。
        </div>
        <div>
          <img src="@/assets/HY20240623/16.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20240623/17.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20240623/18.png" alt="">
        </div>
        <div class="div_p">
          此外，河北省人民医院陈英敏教授、河北医科大学第三医院赵建教授、华北理工大学附属医院张惠英教授、河北医科大学第一医院张力教授、承德医学院附属医院王胜林教授、河北医科大学第一医院张志坤教授出席会议并担任主持人。
        </div>
        <div>
          <img src="@/assets/HY20240623/19.png" alt="">
        </div>
        <div class="div_p">
          京津冀三地医学影像学专家及同仁600余人齐聚此次盛会，会议在浓厚的学术氛围中圆满闭幕。河北省医学影像质控中心工作会议的成功举办，不仅为各位专家学者提供了一个交流与学习的平台，也提高了全省医学影像质量控制工作者的责任心和重视度，相信会更加助力医学影像质控工作的稳步推进，提升全省影像医疗水平，为广大百姓提供更优质的医疗服务。
        </div>
        

      </section>
    </div>


  </div>
</template>

<script>
export default {
  name: "GDS2024"
}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 655px !important;
  box-sizing: border-box;
  height: auto !important;
  visibility: visible !important;
  margin: 10px 0;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}
</style>